
import {
  IonPage,
  IonContent,
  actionSheetController,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonRefresher,
  IonRefresherContent,
  IonImg,
} from "@ionic/vue";
import { defineComponent, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../components/Empty.vue";
import { Analytics } from "../../../common/analytics";

/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    Empty,
    IonRefresher,
    IonRefresherContent,
    IonImg,
  },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props) {
    const router = useRouter();
    const screenName = `PAGE-EVENT_${props.id}`;
    const analytics = new Analytics();
    const state = reactive({
      event: {
        loading: true,
        data: {
          id: 0,
          content: {
            file: [
              {
                url: "",
              },
            ],
          },
        },
      },
      contentLoading: true,
    });

    const fetch = async (event: any = null) => {
      console.log(props.id);
      await services.eventFindone(props.id).then(
        (response) => {
          const { data } = response;
          console.log(data);
          if (event) {
            if (event.type == "ion-refresh") {
              state.event.data.id = 0;
            }
          }

          state.event.data = data;
          state.event.loading = false;
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.event.data.id = 0;
            }
          }
          state.event.loading = false;
        }
      );
      setTimeout(() => {
        if (event) {
          event.target.complete();
        }
        state.contentLoading = false;
      }, 500);
    };

    const presentActionSheet = async () => {
      const actionSheet = await actionSheetController.create({
        header: "공유하기",
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "카카오톡",
            role: "destructive",
            handler: () => {
              console.log("카카오톡 clicked");
            },
          },
          {
            text: "페이스북",
            handler: () => {
              console.log("페이스북 clicked");
            },
          },
          {
            text: "이메일",
            handler: () => {
              console.log("이메일 clicked");
            },
          },
          {
            text: "링크복사",
            handler: () => {
              console.log("링크복사 clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.event.loading = true;
      fetch(event);
    };

    const onEventLink = () => {
      router.push({ name: "Event" });
    };

    // onMounted
    onMounted(() => {
      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return { presentActionSheet, state, onRefresh, onEventLink };
  },
});
